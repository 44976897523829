import React from 'react';

import { tournamentName, eventName, eventDate } from '../utils/tournamentName';

import './SelectedEvent.css';

import Date from './input/Date';
import Quality from './input/Quality';
import SerieMgmt from './input/SerieMgmt';
import EventState from './input/EventState';

const SelectedEvent = props => {
    const selected = props.selected;
    const series = props.series;
    const eventChangeHandler = props.eventChangeHandler;

    if( !selected ) return '';

    const eventStatus = {
        approved: selected.approved,
        hasContent: selected.hasContent,
        contentIsFinal: selected.contentIsFinal
    }

    // add myKey because Text keeps state
    return (
        <div>
            <div className='SelectedEvent-header'>{tournamentName(selected)} ({eventName(selected)})</div>
            <Date header='Event Date'
                defaultValue={eventDate(selected)}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />
            <Quality header='Quality'
                defaultValue={selected.qualityText}
                quality={selected.quality}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />
            { selected.approved ? <SerieMgmt header='Series'
                series={series}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} /> : '' }
            <EventState header='Event Data'
                eventStatus={eventStatus}
                isTeam={selected.isTeam}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />

        </div>
    );
}

export default SelectedEvent;