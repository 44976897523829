import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './FencerStats.css'

const difficulty = (thisDifficulty, total, lang) => {
    const half = total / 2.0 + 0.5;
    const boundary = total / 6;

    if( thisDifficulty === 1 ) return localize(lang, 'mDifficult');
    else if( thisDifficulty < boundary ) return localize(lang, 'vDifficult');
    else if( thisDifficulty < half - boundary ) return localize(lang, 'rDifficult');
    else if( thisDifficulty < half + boundary ) return localize(lang, 'average');
    else if( thisDifficulty < total - boundary ) return localize(lang, 'rEasy');
    else if( thisDifficulty === total ) return localize(lang, 'easiest');
    else return localize(lang, 'qEasy');
}

const FencerStats = props => {
    const stats = props.stats;
    const lang = useContext(LocalizedContext);

    const verbiage = localize(lang, 'finalResultComp');
    var progress = '';
    if( stats.progress === 0 ) {
        progress = <div className='FencingStats-line'>{verbiage}: <span className='FencingStats-zero'>{stats.progress}</span></div>;
    } else if ( stats.progress > 0 ) {
        progress = <div className='FencingStats-line'>{verbiage}: <span className='FencingStats-plus'>+{stats.progress}</span></div>;
    } else if ( stats.progress < 0 ) {
        progress = <div className='FencingStats-line'>{verbiage}: <span className='FencingStats-minus'>{stats.progress}</span></div>;
    }

    const touches = stats.touchesGiven - stats.touchesReceived;
    var touchesDesc = '';
    if( touches === 0 ) {
        touchesDesc = <span className='FencingStats-zero'>{touches}</span>;
    } else if( touches > 0 ) {
        touchesDesc = <span className='FencingStats-plus'>+{touches}</span>;
    } else if( touches < 0 ) {
        touchesDesc = <span className='FencingStats-minus'>{touches}</span>;
    }

    var pools = '';
    if( 'poolDifficulty' in stats ) {
        const poolDiff = stats.poolDifficulty;
        const difficultyDesc = difficulty(poolDiff.difficulty, poolDiff.total, lang);
        pools =             
        <div className='FencingStats-line'>
            {localize(lang, 'poolDifficulty')}:&nbsp;&nbsp;
            <span className='FencingStats-zero'>{difficultyDesc}</span>&nbsp;&nbsp;
            <span className='FencingStats-hint'>{poolDiff.difficulty} / {poolDiff.total}</span>
        </div>
    }

    var de = '';
    if( 'deDifficulty' in stats ) {
        const deDiff = stats.deDifficulty;
        const difficultyDesc = difficulty(deDiff.difficulty, deDiff.total, lang);
        de =             
        <div className='FencingStats-line'>
            {localize(lang, 'deDifficulty')}:&nbsp;&nbsp;
            <span className='FencingStats-zero'>{difficultyDesc}</span>&nbsp;&nbsp;
            <span className='FencingStats-hint'>{deDiff.difficulty} / {deDiff.total}</span>
        </div>
    }

    return (
        <div>
            <div className='FencingStats-line'>
                {localize(lang, 'finalResult')}: <span className='FencingStats-zero'>{stats.place}</span>
            </div>
            {progress}
            <div className='FencingStats-line'>
                {localize(lang, 'touches')}: <span className='FencingStats-plus'>{stats.touchesGiven}</span> {localize(lang, 'given')},
                &nbsp;&nbsp;
                <span className='FencingStats-minus'>{stats.touchesReceived}</span> {localize(lang, 'received')}
                &nbsp;&nbsp;
                ({localize(lang, 'difference')}: {touchesDesc})
            </div>
            <div className='FencingStats-line'>
                {localize(lang, 'bouts')}: <span className='FencingStats-plus'>{stats.wins}</span> {localize(lang, 'winsSmall')},
                &nbsp;&nbsp;
                <span className='FencingStats-minus'>{stats.defeats}</span> {localize(lang, 'defeatsSmall')}
            </div>
            {pools}
            {de}
        </div>
    );
}

export default FencerStats;
