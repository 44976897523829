import React, { useContext, useState } from 'react';

import localize from '../../i18n/localize';
import { tournamentName, eventName } from '../../utils/tournamentName';

import LocalizedContext from '../../i18n/LocalizedContext';

import './TournamentGraph.css';
import '../../essentials/EssentialButton.css';

import BoutResult from './BoutResult';
import FencerLine from './FencerLine';
import FencerStats from './FencerStats';
import constants from './constants';
import OneSerie from '../series/OneSerie';

const TournamentGraph = props => {
    const lang = useContext(LocalizedContext);

    const lineCount = props.fencers.length;
    const resultCount = Math.max(...props.fencers.map(f => f.results.length));
    const lines = props.fencers.map( f => <FencerLine key={f.name} lineCount={lineCount} resultCount={resultCount} standings={f.results} selected={false} /> );
    const svgDimensions = '0 0 ' + constants.WIDTH + ' ' + constants.HEIGHT;

    const fencerSelectedHandler = props.changeFencer;
    const boutResults = props.selected.bouts.map( (b, index) => {
        return <BoutResult key={index} bout={b} changeFencer={fencerSelectedHandler}/>
    });

    const event = props.event;
    const otherEvents = props.otherEvents;
    const setEventHandler = props.setEventHandler;
    const [isSelectingEvent, setIsSelectingEvent] = useState(false);

    const stats = 'stats' in props.selected ? <FencerStats stats={props.selected.stats}/> : '';

    return isSelectingEvent ? (
            <div>
                <div className='TournamentGraph-label'>
                    {localize(lang, 'selectEvent')}
                </div>
                <div className='TournamentGraph-text'>
                    {localize(lang, 'selectOne')}
                </div>
                <OneSerie events={otherEvents} setEventHandler={setEventHandler}/>
            </div>
        ) : (
            <div>
                <div className='TournamentGraph-label'>
                    {tournamentName(event)} ({eventName(event)})
                    <button className='EssentialButton' onClick={ () => setIsSelectingEvent( ! isSelectingEvent ) } >
                        {localize(lang, 'change')}
                    </button>
                </div>
                <div className='TournamentGraph-label'>
                    {localize(lang, 'overallProgress')}
                </div>
                <div className='TournamentGraph-text'>
                    {localize(lang, 'linesRepresent')} {props.selected.name}
                </div>
                <svg viewBox={svgDimensions}>
                    {lines}
                    <FencerLine lineCount={lineCount} resultCount={resultCount} standings={props.selected.results} selected={true} />
                </svg>
                <div className='TournamentGraph-label'>
                    {localize(lang, 'boutResults')}
                </div>
                {boutResults}
                <div className='TournamentGraph-label'>
                    {localize(lang, 'overallStatistics')}
                </div>
                {stats}
            </div>
        );
}

export default TournamentGraph;